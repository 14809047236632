<template lang="pug">
#CustomFields
    v-row.setting-title.align-center.py-3.px-4(no-gutters)
        v-col(cols="12", md="4" ).mr-auto
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size="19", color="white") mdi-view-list-outline
                span {{$t('SETTING.TABLESETTING')}}
    section.px-4.py-3 
      v-row.align-center(no-gutters)
        v-col.d-flex(cols="8")
            label.mr-4.w-50 {{$t('TABLESETTING.PAGE')}}
                v-select(v-model='tab' :items='tab_items' @change='getTable' height='34' flat dense solo background-color='#F0F0F0')
                    template(v-slot:selection='{ item }')
                        .t-black {{ getItemNameFromList(classTypeList, item) }}
                    template(v-slot:item='{ item }')
                        .t-black {{ getItemNameFromList(classTypeList, item) }}
            label.w-50(v-if='tab == "Case" || tab == "Opportunity"') {{$t('TABLESETTING.PROCESS')}}
                v-select( v-model='slcStgType' :items='stageTypes' @change='byClsName' item-text='stage_type_name' item-value='id' return-object  height='34' flat dense solo background-color='#F0F0F0')
                    template(v-slot:prepend-inner)
                        v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
        v-col.d-flex.justify-end(cols="4")
            v-btn.mt-6( @click="showAddTableDialog" height="40", color="green",  depressed, dark)
                v-icon.mr-1(size="14") icon-add
                span {{$t('TABLESETTING.NEW')}}
      v-data-table.custom-view-table( item-key="editId" 
        :headers="header" 
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
        :items="tables",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        height="600",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense,
        :loading = 'loading'
      )
        template( v-slot:item.name="{ item }")
            .ellipsis( :title='item.name')
                span {{item.name}}
        template(v-slot:item.is_default="{ item }")
            v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.is_default" hide-details="auto" @click='changeDefault(item)' :class='item.is_default == true ? "no-pointer-event" : ""')
        template(v-slot:item.active="{ item }" )
          v-btn(icon @click="showEditTableDialog(item)")
            v-icon(size="18" ) icon-edit
          v-btn(icon @click="showCopyTableDialog(item)")
            v-icon(size="18") mdi-content-copy
          v-btn(icon @click="deleteTable(item)" :disabled='item.owner == null')
            v-icon(size="18") icon-remove

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addTableDialog' width='360' content-class='statusDialog' v-if='addTableDialog')
        add-table-dialog(@emitAddTableDialog='onEmitAddTableDialog' @emitAddTable='onEmitAddTable' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypes' :custom-views='customViews')
    v-dialog(v-model='editTableDialog' width='360' content-class='statusDialog' v-if='editTableDialog')
        edit-table-dialog(@emitEditTableDialog='onEmitEditTableDialog' @emitEditTable='onEmitEditTable' :custom-views='customViews' :stage-type='slcStgType' :edit-data='editData' :edit-mode='editMode')
</template>

<script>
import Vue from "vue";
import addTableDialog from "@/components/Dialog/Setting/addTableDialog";
import editTableDialog from "@/components/Dialog/Setting/editTableDialog";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import draggable from 'vuedraggable';

import classTypeList from "@/array/the_classTypeList";
import CustomViewDataService from '@/services/CustomViewDataService';
import StageDataService from '@/services/StageDataService';
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    draggable,
    messageDialog,
    successDialog,
    errorDialog,
    addTableDialog,
    editTableDialog,
  },
  data() {
    return {  
        // tab
        tab: 'Contact',
        tab_items:['Contact', 'Opportunity', 'Organization','Case'],
        // dialog
        messageDialog: false,
        message: '',
        successDialog: false,
        errorDialog: false,
        errorMessage: '',
        addTableDialog: false,
        editTableDialog: false,
        customViews: [],
        editData: [],
        stageTypes: [],
        slcStgType: null,
        editMode: '',
        // table  
        loading: false,
        header: [
            { text: i18n.t('TABLESETTING.DEFAULT'), value: "is_default", align: "center" , width: '10%' ,class: "blue lighten-5", sortable: false,},
            { text: i18n.t('TABLESETTING.NAME'), value: "name", align: "left" , width: '70%' ,class: "blue lighten-5" },
            { text: i18n.t('GENERAL.ACTIVE') , value: "active", align: "center", width: '20%' ,class: "blue lighten-5", sortable: false},
        ],
        tables: [],
        page: 1,
        pageCount: 1,
        itemPerPage: 12,     
        deleteData: null,
        mode: null,
        // list
        classTypeList: classTypeList,
    };
  },
    created() {
        this.getTable();
    },
    methods: {  
        getTable(){
            if(this.tab == 'Contact' || this.tab == 'Organization'){
                this.slcStgType = null;
                this.byClsName();
            }
            else{
                this.byStgType();
            }
        },
        byClsName(){
            this.loading = true;
            this.tables = [];

            CustomViewDataService.getCustomView(this.tab)
            .then(response => {
                this.customViews = response.data;
                this.customViews.forEach(e => {
                    if(e.owner == null){
                        if(this.tab == 'Contact' || this.tab == 'Organization'){
                            let item = {
                                id: e.id,
                                name: e.definition[0].name,
                                is_default: e.definition[0].is_default,
                                fields: e.definition[0].fields,
                                owner: e.owner
                            }
                            this.tables.push(item)
                        }
                        else{
                            let item = {
                                id: e.id,
                                name: e.definition[0].name,
                                is_default: false,
                                fields: e.definition[0].fields,
                                owner: e.owner
                            }

                            if(typeof e.definition[0].is_default == 'object'){
                                let is_default_in_slcstgType = e.definition[0].is_default.includes(String(this.slcStgType.id));
                                if(is_default_in_slcstgType){
                                    item.is_default = true;
                                }
                            }else if(typeof e.definition[0].is_default == 'boolean'){
                                item.is_default = e.definition[0].is_default;
                            }
                            
                            this.tables.push(item)
                        }
                        
                    }
                    else if(e.owner != null){
                        e.definition.forEach(table => {
                            if(this.tab == 'Contact' || this.tab == 'Organization'){
                                let item = {
                                    id: e.id,
                                    name: table.name,
                                    is_default: table.is_default,
                                    fields: table.fields,
                                    owner: e.owner
                                }
                                this.tables.push(item)
                            }
                            else{
                                if(table.stage_type_id == this.slcStgType.id){
                                    let item = {
                                        id: e.id,
                                        name: table.name,
                                        is_default: table.is_default,
                                        fields: table.fields,
                                        owner: e.owner
                                    }
                                    this.tables.push(item)
                                }
                            }
                        })
                    }
                });
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        byStgType(){
            this.loading = true;
            let tab = '';

            if(this.tab == 'Case')
                tab = "case";
            else if(this.tab == 'Opportunity')
                tab = "deal";

            StageDataService.stageList(tab)
            .then(response => {
                this.stageTypes = response.data;
                this.stageTypes.unshift({stage_type_name: i18n.t('GENERAL.ALL'), id: 'all'});
                this.slcStgType = this.stageTypes[0];
                this.byClsName();
            })
            .catch(response => {
                this.showErrorDialog(response)
            } )
           
        },
        changeDefault(item){
            
            if(item.is_default == true){
                // change all the other table to  is_default='false'
                this.tables.forEach(e => {
                    if(e != item){
                        this.$set(e, "is_default", false);
                    }
                })
                if(this.tab == 'Contact' || this.tab == 'Organization'){
                // if Contact and Organization
                    this.customViews.forEach(o => {
                        o.definition.forEach(e => {
                            if(e.fields == item.fields){
                                e.is_default = item.is_default;
                            }
                            else{
                                e.is_default = false;
                            }
                        })
                        CustomViewDataService.updateCustomView(o)
                        .catch(response => {
                            this.showErrorDialog(response)
                        })
                    })
                }
                else{
                // if Opportunity or Case
                // for update seed data and user's own data row
                // we have to deal with it seperately 
                    this.customViews.forEach(o => {
                        if(o.owner != null){
                        // if user's own data row
                            o.definition.forEach(e => {
                                if(e.fields == item.fields){
                                    e.is_default = true;
                                }
                                else{
                                    if(String(e.stage_type_id) == String(this.slcStgType.id)){
                                        e.is_default = false;
                                    }   
                                }
                            })
                            CustomViewDataService.updateCustomView(o)
                            .catch(response => {
                                this.showErrorDialog(response)
                            })
                        }

                        if(o.owner == null){ // if seed data
                            if(item.fields == o.definition[0].fields){ // if the table user set to default is from seed data
                                if(typeof( o.definition[0].is_default) != 'object'){
                                    o.definition[0].is_default = [];
                                    o.definition[0].is_default.push(String(this.slcStgType.id));
                                }
                                else{
                                    let is_include = o.definition[0].is_default.includes(String(this.slcStgType.id)); 
                                    if(!is_include){
                                        o.definition[0].is_default.push(String(this.slcStgType.id));
                                    }
                                }
                                
                            }
                            else{ // if the table user set to default is not from seed data
                                if(typeof( o.definition[0].is_default) == 'object'){
                                    let is_include = o.definition[0].is_default.includes(String(this.slcStgType.id));
                                    if(is_include){
                                        let index = o.definition[0].is_default.indexOf(String(this.slcStgType.id));
                                        o.definition[0].is_default.splice(index, 1);
                                    }
                                }
                                else if(typeof( o.definition[0].is_default) != 'object'){
                                    o.definition[0].is_default = [];
                                    this.stageTypes.forEach(stage => {
                                        if(stage.id != this.slcStgType.id){
                                            o.definition[0].is_default.push(String(stage.id))
                                        }
                                    })
                                }
                            }
                            CustomViewDataService.updateCustomView(o)
                            .catch(response => {
                                this.showErrorDialog(response)
                            })
                        }
                    })
                }
            }
        },
        // dialog
        showAddTableDialog(){
            this.addTableDialog = true;
        },
        onEmitAddTableDialog(val){
            if(val){
                this.showSuccessDialog();
            }
            this.addTableDialog = false;
        },
        onEmitAddTable(data){
            let item = {
                id: data.id,
                name: data.definition[0].name,
                is_default: data.definition[0].is_default,
                fields: data.definition[0].fields,
                owner: data.owner
            }

            this.customViews.forEach(e => {
                if(e.owner != null){
                    let index = e.definition.indexOf(data.definition[0]);
                    if(index == -1){
                        e.definition.push(data.definition[0])
                    }
                }
            })
            this.tables.unshift(item)
        },
        showEditTableDialog(item){
            this.editMode = 'EDIT';
            this.editData = item;
            this.editTableDialog = true;
        },
        showCopyTableDialog(item){
            this.editMode = 'COPY';
            this.editData = item;
            this.editTableDialog = true;
        },
        onEmitEditTableDialog(val){
            if(val){
                this.showSuccessDialog();
            }
            this.editTableDialog = false;
        },
        onEmitEditTable(data){
            if(this.editMode == 'EDIT'){
                this.editData.name = data.name;
                this.editData.is_default = data.is_default;
                this.editData.fields = data.fields;
            }
            else if(this.editMode == 'COPY'){
                let userOwnCV = this.customViews.find(e => e.owner != null);
                let item = {
                    id: userOwnCV.id,
                    name: data.name,
                    is_default: data.is_default,
                    fields: data.fields,
                    owner: userOwnCV.owner
                }

                this.tables.unshift(item)
            }
        },
        deleteTable(item){
            this.showMessageDialog(i18n.t('TABLESETTING.MESSAGE'));
            this.deleteData = item;
            this.mode = 'delete';
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        async onEmitMessageDialog(val) {
            this.messageDialog = false;
            if(val){
                // if delete data
                if(this.mode == 'delete'){

                    // find data to delete and give default table
                    let deleteData;
                    let updateData;
                    let delete_index;

                    this.customViews.forEach(e => {
                        if(e.owner != null){
                            // if is default
                            delete_index = e.definition.findIndex(o => o.fields == this.deleteData.fields);
                            e.definition.splice(delete_index, 1);
                            deleteData = e;
                        }
                        else{
                            // this will only be use if deleteData.is_default == true
                            if(this.deleteData.is_default){
                                if(this.tab == 'Opportunity' || this.tab == 'Case'){
                                    if(typeof(e.definition[0]) == 'object'){
                                        e.definition[0].is_default.push(String(this.slcStgType.id));
                                    }
                                }
                                else{
                                    e.definition[0].is_default = true;
                                }
                                updateData = e;
                            }
                        }
                    })
                    // call api to update
                    await CustomViewDataService.updateCustomView(deleteData)
                    .then(() => {
                        let table_index = this.tables.findIndex(i => 
                            i == this.deleteData
                        )
                        this.tables.splice(table_index, 1);
                        // if is default table row
                        if(this.deleteData.is_default){
                            CustomViewDataService.updateCustomView(updateData)
                            .then(() => {
                                let table_index = this.tables.findIndex(i => 
                                    i.owner == null
                                )
                                this.tables[table_index].is_default = true;
                                this.showSuccessDialog();
                            })
                            .catch(response => {
                                this.showErrorDialog(response)
                            })
                        }
                        else{
                            this.showSuccessDialog();
                        }
                    })
                    .catch(response => {
                        this.showErrorDialog(response)
                    })
                }
            }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        showErrorDialog(message) {  
            this.errorDialog = true;      
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
    },
});
</script>