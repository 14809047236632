<template lang="pug">
    #ManageGroups    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        v-row.setting-title.align-center.px-4.py-3(no-gutters)
            v-col(cols='12' md='8')
                h3.d-flex.align-center.mb-0
                    .cube-icon.small.bg-primary.mr-3
                        v-icon(size='17' color='white') mdi-file-tree
                    span {{$t('SETTING.TREESETTING')}}
        .px-4.py-3
            v-row.align-center.justify-space-between.mb-2(no-gutters)
                v-col(cols='4')
                    v-text-field.pa-0(v-model='searchTS' flat dense solo height='40' min-height='40' prepend-inner-icon='icon-search')
                v-col.pl-4(cols='3').d-flex.justify-end
                    v-btn(@click='showAddTSDialog' height='40' color='green' depressed dark)
                        v-icon.mr-1(size='14') icon-add
                        span {{$t('TREESETTING.ADD')}}
            v-data-table.dashboard-table(
                multi-sort
                :headers='manageTSHeaders' 
                :items='manageTSData' 
                :items-per-page='itemPerPage' 
                :page.sync='page' 
                :item-class="rowClass"
                fixed-header 
                @page-count='pageCount = $event' 
                :search='searchTS' 
                height='715' 
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
            )
                template(v-slot:item.name='{ item }')
                    .ellipsis.pl-3
                        span(:title='item.name') {{ item.name }}    
                template(v-slot:item.permission_type_name='{ item }')
                    .ellipsis
                        span(:title='item.permission_type_name') {{ item.permission_type_name }}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditTSDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)')
                        v-icon(size='18') icon-remove
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(v-model='manageTSDialog' max-width='500' content-class='setting-dialog' v-if='manageTSDialog')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{ addOrEdit }} {{$t('TREESETTING.TS')}}
                v-card-text
                    v-form(v-model='addOrEditTS.valid' ref='form')
                        v-row(no-gutters)    
                            v-col.mt-5(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='addOrEditTS.name' :label=`$t('MANAGEGROUPS.NAME')`, solo prepend-icon="mdi-rename-box" :rules="formRule.fieldRules")
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onAddTS(addOrEditTS)' width='100%' height='40' color='success' depressed dark) {{ addOrEditButton }}
        v-dialog(v-if='editDialog' v-model='editDialog' width='700' content-class='statusDialog' persistent @keydown.esc='onEmitEditDialog(false)')
            edit-dialog(@emitEditDialog='onEmitEditDialog' :treeSelectData='editData' :editMode='editMode' )
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import TreeOptionsDataService from "@/services/TreeOptionsDataService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay";    
    import i18n from '/common/plugins/vue-i18n.js';
    import editDialog from "@/components/Dialog/Setting/addeditTreeSelectDialog.vue";

    export default Vue.extend({
        props: {  
            
        },
        components: {  
            loadingOverlay,
            messageDialog,
            successDialog,
            errorDialog,
            editDialog,
        },        
        data() {
            return {
                editTSIndex:null,
                originData:null,
                editData:null,
                editDialog:false,
                editMode:null,
                addOrEdit: i18n.t('GENERAL.ADD'),
                addOrEditButton: i18n.t('GENERAL.ADD'),
                loading: false,    
                deleteTSID: null,
                userId:null,
                manageTSData: [],
                page: 1,
                pageCount: 1,
                itemPerPage: 100,
                manageTSHeaders: [
                    {  text: i18n.t('MANAGEGROUPS.NAME'), value: 'name', align: 'left',width:'45%', class: "blue lighten-5"},
                    // {  text: i18n.t('MANAGEGROUPS.TYPE'), value: 'permission_type_name', align: 'center',width:'40%', class: "blue lighten-5"},
                    {  text: i18n.t('MANAGEGROUPS.ACTIVE'), value: 'active', align: 'center',width:'15%', class: "blue lighten-5", sortable: false},
                ],
                searchTS: "",
                manageTSDialog: false,
                addOrEditTS : this.initializeAddOrEditTS(),
                errorMessage: "",
                message: "",
                messageDialog:false,
                errorDialog:false,
                successDialog:false,
                valid:false,            
                ownerList: [],
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    userfieldRules: [(v) =>  v.length>0 || i18n.t('RULE.RULE_R')],
                },
            }
        },
        async created()
        {
            await TreeOptionsDataService.list().then((response)=>{
                this.manageTSData = response.data;
            })
        },
        methods:{
            geTreeSelectDisplay() {
                this.manageTSData = this.manageTSData.map(
                    g => ({ ...g, 
                            customLabel : "later" })
                );
            },
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)
                    return true;
                else
                    return false;
            },
            getNoAccessPrivilegeMessage() {
                return i18n.t('MANAGEGROUPS.ERROR');
            },
            getDuplicateNameMessage() {
                return i18n.t('TREESETTING.ERROR');
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                if(val){
                    TreeOptionsDataService.delete(this.deleteTSID)
                    .then((response)=> {
                        if(response.data){
                            this.showErrorDialog(i18n.t('TREESETTING.IS_USING'));
                        }else{
                            let deleteItemIndex = this.manageTSData.findIndex(g => g.id == this.deleteTSID);
                            this.manageTSData.splice(deleteItemIndex, 1);
                            this.deleteTSID = null;
                            this.showSuccessDialog();
                        }
                    }).catch((response) => {
                        this.showErrorDialog(response.data);
                    });
                }
            }, 
            hasPrivilege() {
                return this.isAdmin;
            },
            initializeAddOrEditTS() {
                return {
                    id: "",
                    name:"",
                    value:[],
                    option_set:{},
                    max_key:0
                };
            },
            showAddTSDialog() {
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.editData = this.initializeAddOrEditTS();
                this.editMode = "ADD";
                this.editDialog = true;
                // this.manageTSDialog = true;
                // this.addOrEdit = i18n.t('GENERAL.ADD');
                // this.addOrEditButton = i18n.t('GENERAL.ADD');
                // this.addOrEditTS = 
            },
            showEditTSDialog(data) {
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.editData = data;
                this.editMode = "EDIT";
                this.editDialog = true;
                // this.addOrEdit = i18n.t('GENERAL.EDIT');
                // this.addOrEditButton = i18n.t('GENERAL.SAVE');
                // this.addOrEditTS = JSON.parse(JSON.stringify(data));
                this.originData = JSON.parse(JSON.stringify(this.manageTSData));
                this.editTSIndex = this.manageTSData.findIndex(g => g == data);
            },
            async editTS(data) {
                this.loading = true;
                await TreeOptionsDataService.update(data)
                    .then((response)=>{
                        if(response.data.id)
                        {
                            this.manageTSData[this.editTSIndex] = response.data;
                            this.geTreeSelectDisplay();
                            this.showSuccessDialog();
                            this.loading = false;
                        }
                    }).catch((response) => {
                        this.showErrorDialog(response);
                        this.loading = false;
                    });
            },
            async addTS(data) {
                this.loading = true;
                await TreeOptionsDataService.create(data)
                    .then((response)=>{
                        if(response.data.id)
                        {
                            let newTS = response.data;
                            this.manageTSData.unshift(newTS);
                            this.geTreeSelectDisplay();
                            this.showSuccessDialog();
                            this.loading = false;
                        }
                    })
                    .catch((exception) => {
                        this.showErrorDialog(exception);
                        this.loading = false;
                    });
            },
            async onAddTS(data) {
                if(this.isAdmin === false)
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                if(this.manageTSData.find(el => el.name == data.name))
                        {
                            this.showErrorDialog(this.getDuplicateNameMessage());
                            return;
                        }
                if(this.validateForm()){
                    // Add
                        if(this.manageTSData.find(el => el.name == data.name))
                        {
                            this.showErrorDialog(this.getDuplicateNameMessage());
                            return;
                        }
                        await this.addTS(data);
                    this.manageTSDialog = false;
                    this.addOrEditTS = this.initializeAddOrEditTS();
                }
            },
            onCancel(){
                this.manageTSDialog = false
                this.addOrEditTS = this.initializeAddOrEditTS();
            },
            onDelete(item) {
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.showMessageDialog(i18n.t('TREESETTING.DEL_MSG'));
                this.deleteTSID = item.id;
            },
            rowClass() {        
                const rowClass = 'dataTableColor'
                return rowClass;
            },
            onEmitEditDialog(val,data,mode){
                if(val){
                    if(this.isAdmin === false)
                    {
                        this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                        return;
                    }
                    if(this.manageTSData.find(el => el.name == data.name && el.id != data.id))
                    {
                        this.showErrorDialog(this.getDuplicateNameMessage());
                        return;
                    }
                    if(mode=="EDIT"){
                        this.editTS(data);
                    }
                    else if(mode=="ADD"){
                        this.addTS(data);
                    }
                }
                this.editDialog = false;
            },
        },
        watch:{
            "editDialog":function(){
                if(this.editMode == "EDIT" && this.editDialog == false){
                    this.manageTSData = this.originData;
                }
            },
        }
    });
</script>