<template lang="pug">
#CustomFields
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="11", md="11")
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="22", color="white") mdi-folder-upload
        span {{$t('SETTING.UPLOADSTATUS')}}
    v-col(cols="1").d-flex.justify-end
        v-btn(v-if='selectedRows.length>0' @click="onDelete()" height="40" color="error" depressed dark)
          v-icon.mr-1(size="14") icon-remove
          span {{$t('GENERAL.DELETE')}}
        v-btn.ml-2(@click="getList()" height="40" color="green" depressed dark)
          v-icon.mr-1(size="14") mdi-refresh
          span {{$t('GENERAL.REFRESH')}}
  .px-4.py-3 
    section
      v-data-table.dashboard-table(
        item-key="id",
        v-model="selectedRows" 
        :item-class="rowClass"
        :headers="fieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="uploadStatusList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        show-select
        :search="searchMangeUser",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
        height='1000' 
      )
        template( v-slot:item.method="{ item }" )
            .ellipsis(:title='getI18nName(item.method)' height='36px')
              span {{getI18nName(item.method)}}
        template( v-slot:item.status="{ item }" )
            .ellipsis(:title='getI18nName(item.status)')
              span {{getI18nName(item.status)}}
        template( v-slot:item.created_on="{ item }" )
            .ellipsis(:title='getFormatTime(item.created_on)')
              span {{getFormatTime(item.created_on)}}
        template( v-slot:item.updated_on="{ item }" )
            .ellipsis(:title='getFormatTime(item.updated_on)')
              span {{getFormatTime(item.updated_on)}}
        template( v-slot:item.type="{ item }" )
            .ellipsis(:title='getI18nName(item.type)')
              span {{getI18nName(item.type)}}
        template( v-slot:item.file_name="{ item }" )
            .ellipsis(:title='item.file_name')
              span {{item.file_name == "WEB" && item.type == "WEB" ? $t('UPGPT.WEBSITEANALYSIS'):item.file_name}}
        template( v-slot:item.total="{ item }" )
            .ellipsis(:title='item.total')
              span {{item.total}}
        template( v-slot:item.success="{ item }" )
            .ellipsis(:title='item.success')
              span {{item.success}}
        template( v-slot:item.fail="{ item }" )
            .ellipsis(:title='item.fail')
              span {{item.fail}}
        template( v-slot:item.comment="{ item }" )
            v-btn(@click="showRecords(item.comment)" height="40" color="primary" depressed dark)
              span {{$t('UPLOADSTATUS.SHOW')}}

      v-pagination.mt-3(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading')
    v-dialog(
      v-model="recordsDialog",
      max-width="500",
      content-class="setting-dialog",
      v-if="recordsDialog === true"
    )
      v-card
        v-card-text
          v-textarea.mt-4.textarea-style(
            id='textarea_id',
            v-model="records",
            :label="$t('UPLOADSTATUS.NO_RECORD')"
            solo
            no-resize
            readonly
            height='500'
          )
          v-snackbar(v-model="snackbar" :timeout="1000" 
            absolute
            centered
            color="primary"
          ) {{$t("IMPORT_EXPORT.COPY_2") }}
          v-card-actions.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  v-if='records.length>0'
                  :ripple="false",
                  @click="onCopyMsg",
                  width="100%",
                  height="40",
                  color="primary",
                  depressed,
                  dark
                ) {{$t('IMPORT_EXPORT.COPY')}}
                
              v-col.pr-2(:cols="records.length>0? '6':'12'")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="green",
                  depressed,
                  dark
                ) {{$t('GENERAL.CONFIRM')}}
              
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import UploadStatusDataService from "@/services/UploadStatusDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      selectedRows:[],
      recordsDialog:false,
      records:null,
      snackbar:false,
      uploadStatusList:[],
      fieldsHeader: [
        { value: "data-table-select",align: "center",width: '50px',class: "blue lighten-5",},
        { text: i18n.t('UPLOADSTATUS.METHOD'), value: "method", align: "left" , width: '20%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.TYPE'), value: "type", align: "left" , width: '25%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.CREATE_TIME'), value: "created_on", align: "left" , width: '35%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.UPDATED_TIME'), value: "updated_on", align: "left" , width: '35%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.FILE_NAME'), value: "file_name", align: "left" , width: '35%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.TOTAL'), value: "total", align: "left" , width: '15%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.SUCCESS'), value: "success", align: "left" , width: '15%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.FAIL'), value: "fail", align: "left" , width: '15%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.STATUS'), value: "status", align: "left" , width: '20%' ,class: "blue lighten-5", sortable: true, },
        { text: i18n.t('UPLOADSTATUS.COMMENT'), value: "comment", align: "left" , width: '25%' ,class: "blue lighten-5", sortable: false, },
      ],
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',      
      searchMangeUser: "",
      page: 1,
      pageCount: 1,
      itemPerPage: 12,
      loading:false,
      delete_list:[],
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList(){
      this.loading = true;
      await UploadStatusDataService.list().then((response)=>{
        this.uploadStatusList = response.data;
        this.loading = false;
      });
    },
    showRecords(data){
      this.recordsDialog = true;
      this.records = data;
    },
    onCancel() {
      this.recordsDialog = false;
      this.records = null;
    },
    onCopyMsg(event){      
        const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
        clipboardData.writeText(this.records);
        this.snackbar = true
    },
    getI18nName(str){
      switch(str){
        case "Upload" :
          return i18n.t('UPLOADSTATUS.UPLOAD');
        case "Import" :
          return i18n.t('UPLOADSTATUS.IMPORT');
        case "PROCESSING" :
          return i18n.t('UPLOADSTATUS.PROCESSING');
        case "COMPLETE" :
          return i18n.t('UPLOADSTATUS.COMPLETE');
        case "FAIL" :
          return i18n.t('UPLOADSTATUS.FAIL');
        case "EMBEDDING" :
          return i18n.t('UPLOADSTATUS.EMBEDDING');
        case "UploadFolder" :
          return i18n.t('UPGPT.FOLDERUPLOAD');
        case "UploadQAFile" :
          return i18n.t('UPGPT.QAUPLOAD');
        case "UploadFile" :
          return i18n.t('UPGPT.FILEUPLOAD');
        case "WEB" :
          return i18n.t('UPGPT.WEBSITEANALYSIS');
        case "Contact" :
          return i18n.t('Bower_Title.Contact');
        case "Organization" :
          return i18n.t('Bower_Title.Company');
        case "Opportunity" :
          return i18n.t('Bower_Title.Oppty');
        case "Case" :
          return i18n.t('Bower_Title.Case');
        case "KnowledgeArticle" :
          return i18n.t('Bower_Title.KnowledgeArticle');
        default:
          return str;
      }
    },
    getFormatTime(data){
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
      let today  = new Date(data);
      if(this.$i18n.locale == "zh_TW")
      {
          return today.toLocaleDateString("zh-TW", options); 
      }else
      {
          return today.toLocaleDateString("en-US", options);
      }                
    },
    rowClass() {
      const rowClass = 'dataTableColor'
      return rowClass;
    },
    onDelete(){
      this.delete_list=[];
      for(let i = 0; i < this.selectedRows.length; i++)
        {
          this.delete_list.push(this.selectedRows[i].id)
        }
      this.showMessageDialog(i18n.t('UPLOADSTATUS.MESSAGE_DELETE'));
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val){
        if(this.delete_list.length>0){
          await UploadStatusDataService.bulk_delete(this.delete_list).then(()=>{
            this.selectedRows = [];
            this.delete_list = [];
            this.getList();
          })
        }
      }
      this.messageDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {  
        this.errorDialog = true;      
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
    },
  },
});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
</style>